<template>
  <div class="cancelReason flexcolumnstart">
    <div class="title">请告诉我们注销的原因，来帮助完善我们的服务。</div>
    <div class="content flexcolumnstart">
      <div class="specialtitle">请选择注销理由</div>
      <div class="line"></div>
      <div class="specialtitle flexrowbetween aligncentet">
        <span>更换新账号</span>
        <span
          class="uncheckedRadio marginRight12"
          v-if="!reason1"
          @click="agreeNotice('1')"
        ></span>
        <img
          @click="agreeNotice('1')"
          v-else
          class="checkedRadio marginRight12"
          src="https://sho-static.shulan.com/minipro/doctoragree.png"
        />
      </div>
      <div class="line"></div>
      <div class="specialtitle flexrowbetween aligncentet">
        <span>账号信息有误</span>
        <span
          class="uncheckedRadio marginRight12"
          v-if="!reason2"
          @click="agreeNotice('2')"
        ></span>
        <img
          @click="agreeNotice('2')"
          v-else
          class="checkedRadio marginRight12"
          src="https://sho-static.shulan.com/minipro/doctoragree.png"
        />
      </div>
      <div class="line"></div>
      <div class="specialtitle flexrowbetween aligncentet">
        <span>不再使用本APP</span>
        <span
          class="uncheckedRadio marginRight12"
          v-if="!reason3"
          @click="agreeNotice('3')"
        ></span>
        <img
          @click="agreeNotice('3')"
          v-else
          class="checkedRadio marginRight12"
          src="https://sho-static.shulan.com/minipro/doctoragree.png"
        />
      </div>
      <div class="line"></div>
      <div class="specialtitle flexrowbetween aligncentet">
        <span>其他原因</span>
        <span
          class="uncheckedRadio marginRight12"
          v-if="!reason4"
          @click="agreeNotice('4')"
        ></span>
        <img
          @click="agreeNotice('4')"
          v-else
          class="checkedRadio marginRight12"
          src="https://sho-static.shulan.com/minipro/doctoragree.png"
        />
      </div>
      <van-field
        v-if="reason4"
        style="padding: 0;"
        maxlength="500"
        v-model="otherReason"
        rows="2"
        type="textarea"
        placeholder="请输入详细原因"
      />
    </div>
    <div class="inActiveBtn marginTop70" v-if="!reason1 && !reason2 && !reason3&& !reason4">申请注销</div>
    <div class="activeBtn marginTop70" @click="applayAccount" v-else>申请注销</div>
    <div class="g-safe-area-bottom"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reason1: false,
      reason2: false,
      reason3: false,
      reason4: false,
      otherReason: '',
    }
  },
  methods: {
    applayAccount() {
      if(this.reason4 && this.otherReason == ''){
        this.$toast.fail('请输入详细原因')
      }else{
        let data = []
        if(this.reason1){
          data.push({
            "reasonCode":"1",
        "reasonDesc":"更换新账号"
          })
        }
        if(this.reason2){
          data.push({
            "reasonCode":"2",
        "reasonDesc":"账号信息有误"
          })
        }
         if(this.reason3){
          data.push({
            "reasonCode":"3",
        "reasonDesc":"不再使用本APP"
          })
        }
         if(this.reason4){
          data.push({
            "reasonCode":"4",
        "reasonDesc":this.otherReason
          })
        }
           this.$axios
          .post(
            `/duser/user/cancel/info/submit`,data,
            { basurlType: "commonurl" }
          )
          .then((res) => {
            this.$router.replace({
              path:'/cancelAccount/cancelResult',
            })
          });
      }
    },
    agreeNotice(type) {
        switch(type){
            case '1':
            this.reason1 = !this.reason1
            break;
             case '2':
            this.reason2 = !this.reason2
            break;
             case '3':
            this.reason3 = !this.reason3
            break;
             case '4':
            this.reason4 = !this.reason4
            break;
        }
      
    },
  },
  created() {},
  beforeCreate() {},
}
</script>
<style lang="scss">
.cancelReason {
  background: #f7f9fc;
  padding: 50px 30px;
  .title {
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
    margin-bottom: 44px;
  }
  .content {
    background: #ffffff;
    border-radius: 24px;
    padding: 20px 28px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
  }
  .specialtitle {
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    padding: 25px 0;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #eef1f5;
  }
}
</style>
